h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-size: 1rem;
  line-height: 1.5rem;
}

header, li {
  border: 0;
}

button {
  cursor: pointer;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pjfa-fixed {
  position: fixed;
}

.pjfa-absolute {
  position: absolute;
}

.pjfa-relative {
  position: relative;
}

.pjfa-bottom-0 {
  bottom: 0;
}

.pjfa-bottom-\[calc\(50\%-20px\)\] {
  bottom: calc(50% - 20px);
}

.pjfa-bottom-\[calc\(50\%-32px\)\] {
  bottom: calc(50% - 32px);
}

.pjfa-left-0 {
  left: 0;
}

.pjfa-left-\[calc\(50\%-20px\)\] {
  left: calc(50% - 20px);
}

.pjfa-right-0 {
  right: 0;
}

.pjfa-top-0 {
  top: 0;
}

.pjfa-z-top {
  z-index: 9999;
}

.pjfa-mb-0 {
  margin-bottom: 0;
}

.pjfa-mb-1 {
  margin-bottom: .25rem;
}

.pjfa-mb-2 {
  margin-bottom: .5rem;
}

.pjfa-mb-4 {
  margin-bottom: 1rem;
}

.pjfa-mb-8 {
  margin-bottom: 2rem;
}

.pjfa-mr-2 {
  margin-right: .5rem;
}

.pjfa-block {
  display: block;
}

.pjfa-flex {
  display: flex;
}

.pjfa-hidden {
  display: none;
}

.pjfa-h-10 {
  height: 2.5rem;
}

.pjfa-h-16 {
  height: 4rem;
}

.pjfa-h-\[48px\] {
  height: 48px;
}

.pjfa-h-full {
  height: 100%;
}

.pjfa-w-10 {
  width: 2.5rem;
}

.pjfa-w-16 {
  width: 4rem;
}

.pjfa-w-\[400px\] {
  width: 400px;
}

.pjfa-w-\[48px\] {
  width: 48px;
}

.pjfa-w-full {
  width: 100%;
}

.pjfa-flex-1 {
  flex: 1;
}

.pjfa--translate-x-6 {
  --tw-translate-x: -1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.pjfa--translate-x-8 {
  --tw-translate-x: -2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.pjfa--translate-y-6 {
  --tw-translate-y: -1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.pjfa-translate-x-6 {
  --tw-translate-x: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.pjfa-translate-x-8 {
  --tw-translate-x: 2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.pjfa-translate-x-\[-370px\] {
  --tw-translate-x: -370px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.pjfa-translate-y-6 {
  --tw-translate-y: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-pjfa-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.pjfa-scale-\[2\] {
  --tw-scale-x: 2;
  --tw-scale-y: 2;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.pjfa-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.pjfa-list-none {
  list-style-type: none;
}

.pjfa-items-center {
  align-items: center;
}

.pjfa-justify-center {
  justify-content: center;
}

.pjfa-overflow-y-auto {
  overflow-y: auto;
}

.pjfa-whitespace-pre-wrap {
  white-space: pre-wrap;
}

.pjfa-break-all {
  word-break: break-all;
}

.pjfa-rounded-md {
  border-radius: .375rem;
}

.pjfa-border {
  border-width: 1px;
}

.pjfa-border-2 {
  border-width: 2px;
}

.pjfa-border-y-\[1px\] {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.pjfa-border-b-\[1px\] {
  border-bottom-width: 1px;
}

.pjfa-border-solid {
  border-style: solid;
}

.pjfa-border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgb(219 234 254 / var(--tw-border-opacity));
}

.pjfa-border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgb(134 239 172 / var(--tw-border-opacity));
}

.pjfa-border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgb(254 202 202 / var(--tw-border-opacity));
}

.pjfa-border-slate-200 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.pjfa-border-slate-300 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.pjfa-border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgb(253 224 71 / var(--tw-border-opacity));
}

.pjfa-border-b-slate-200 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.pjfa-border-b-slate-300 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(203 213 225 / var(--tw-border-opacity));
}

.pjfa-border-t-white {
  --tw-border-opacity: 1;
  border-top-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.pjfa-bg-amber-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 230 138 / var(--tw-bg-opacity));
}

.pjfa-bg-amber-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity));
}

.pjfa-bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity));
}

.pjfa-bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.pjfa-bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.pjfa-bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity));
}

.pjfa-bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
}

.pjfa-bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.pjfa-bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity));
}

.pjfa-bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity));
}

.pjfa-bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

.pjfa-bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.pjfa-bg-slate-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.pjfa-bg-slate-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.pjfa-bg-slate-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity));
}

.pjfa-bg-slate-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity));
}

.pjfa-bg-transparent {
  background-color: #0000;
}

.pjfa-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.pjfa-bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity));
}

.pjfa-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}

.pjfa-from-white {
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.pjfa-from-95\% {
  --tw-gradient-from-position: 95%;
}

.pjfa-via-white {
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #fff var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.pjfa-to-slate-300 {
  --tw-gradient-to: #cbd5e1 var(--tw-gradient-to-position);
}

.pjfa-p-3 {
  padding: .75rem;
}

.pjfa-px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.pjfa-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pjfa-py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.pjfa-py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.pjfa-pr-3 {
  padding-right: .75rem;
}

.pjfa-text-left {
  text-align: left;
}

.pjfa-text-center {
  text-align: center;
}

.pjfa-text-right {
  text-align: right;
}

.pjfa-align-text-top {
  vertical-align: text-top;
}

.pjfa-font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.pjfa-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.pjfa-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.pjfa-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.pjfa-font-bold {
  font-weight: 700;
}

.pjfa-font-medium {
  font-weight: 500;
}

.pjfa-text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.pjfa-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.pjfa-opacity-100 {
  opacity: 1;
}

.pjfa-opacity-20 {
  opacity: .2;
}

.pjfa-opacity-30 {
  opacity: .3;
}

.pjfa-mix-blend-multiply {
  mix-blend-mode: multiply;
}

.pjfa-shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.pjfa-grayscale {
  --tw-grayscale: grayscale(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.pjfa-transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.pjfa-transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.pjfa-transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.pjfa-duration-300 {
  transition-duration: .3s;
}

.hover\:pjfa-translate-x-0:hover {
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:pjfa-translate-y-0:hover {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:pjfa-bg-slate-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.hover\:pjfa-text-slate-500:hover {
  --tw-text-opacity: 1;
  color: rgb(100 116 139 / var(--tw-text-opacity));
}

.hover\:pjfa-opacity-40:hover {
  opacity: .4;
}

.hover\:pjfa-mix-blend-normal:hover {
  mix-blend-mode: normal;
}

.hover\:pjfa-grayscale-0:hover {
  --tw-grayscale: grayscale(0);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.pjfa-group:hover .group-hover\:pjfa-opacity-100 {
  opacity: 1;
}
/*# sourceMappingURL=module.css.map */
