@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    @apply pjfa-text-base;
  }
  header, li {
    border: 0;
  }
  button {
    cursor: pointer;
  }
}