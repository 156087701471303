*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.pjra-fixed {
  position: fixed;
}

.pjra-absolute {
  position: absolute;
}

.pjra-relative {
  position: relative;
}

.pjra-bottom-0 {
  bottom: 0;
}

.pjra-left-0 {
  left: 0;
}

.pjra-left-2 {
  left: .5rem;
}

.pjra-left-5 {
  left: 1.25rem;
}

.pjra-right-0 {
  right: 0;
}

.pjra-top-0 {
  top: 0;
}

.pjra-top-10 {
  top: 2.5rem;
}

.pjra-top-2 {
  top: .5rem;
}

.pjra-top-\[50\%\] {
  top: 50%;
}

.pjra-z-\[108\] {
  z-index: 108;
}

.pjra-z-\[99999\] {
  z-index: 99999;
}

.pjra-z-\[9\] {
  z-index: 9;
}

.pjra-m-0 {
  margin: 0;
}

.pjra-mb-2 {
  margin-bottom: .5rem;
}

.pjra-mb-3 {
  margin-bottom: .75rem;
}

.pjra-flex {
  display: flex;
}

.\!pjra-hidden {
  display: none !important;
}

.pjra-hidden {
  display: none;
}

.\!pjra-h-\[calc\(-48px_\+_100svh\)\] {
  height: calc(100svh - 48px) !important;
}

.\!pjra-h-\[calc\(-72px_\+_100svh\)\] {
  height: calc(100svh - 72px) !important;
}

.pjra-h-0 {
  height: 0;
}

.pjra-h-\[150px\] {
  height: 150px;
}

.pjra-h-\[160px\] {
  height: 160px;
}

.pjra-h-\[240px\] {
  height: 240px;
}

.pjra-h-\[250px\] {
  height: 250px;
}

.pjra-h-\[320px\] {
  height: 320px;
}

.pjra-h-\[48px\] {
  height: 48px;
}

.pjra-h-\[600px\] {
  height: 600px;
}

.pjra-h-full {
  height: 100%;
}

.pjra-w-\[300px\] {
  width: 300px;
}

.pjra-w-\[320px\] {
  width: 320px;
}

.pjra-w-\[970px\] {
  width: 970px;
}

.pjra-w-\[980px\] {
  width: 980px;
}

.pjra-w-full {
  width: 100%;
}

.pjra-max-w-\[90\%\] {
  max-width: 90%;
}

.pjra-origin-bottom {
  transform-origin: bottom;
}

.pjra-origin-left {
  transform-origin: 0;
}

.pjra-origin-right {
  transform-origin: 100%;
}

.pjra-translate-y-\[-50\%\] {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-pjra-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.pjra-rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes pjra-bounce {
  0%, 100% {
    animation-timing-function: cubic-bezier(.8, 0, 1, 1);
    transform: translateY(-25%);
  }

  50% {
    animation-timing-function: cubic-bezier(0, 0, .2, 1);
    transform: none;
  }
}

.pjra-animate-bounce {
  animation: 1s infinite pjra-bounce;
}

.pjra-cursor-pointer {
  cursor: pointer;
}

.pjra-list-none {
  list-style-type: none;
}

.pjra-flex-row {
  flex-direction: row;
}

.pjra-flex-col {
  flex-direction: column;
}

.pjra-items-center {
  align-items: center;
}

.pjra-justify-center {
  justify-content: center;
}

.pjra-justify-between {
  justify-content: space-between;
}

.pjra-gap-1 {
  gap: .25rem;
}

.pjra-gap-2 {
  gap: .5rem;
}

.pjra-overflow-hidden {
  overflow: hidden;
}

.pjra-overflow-y-auto {
  overflow-y: auto;
}

.pjra-rounded {
  border-radius: .25rem;
}

.pjra-border {
  border-width: 1px;
}

.pjra-border-solid {
  border-style: solid;
}

.pjra-border-base-200 {
  border-color: #e6e8ea;
}

.pjra-border-black {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.pjra-bg-amber-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(252 211 77 / var(--tw-bg-opacity));
}

.pjra-bg-base-100 {
  background-color: #f7f8f8;
}

.pjra-bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(88 28 135 / var(--tw-bg-opacity));
}

.pjra-bg-slate-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity));
}

.pjra-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.pjra-bg-opacity-80 {
  --tw-bg-opacity: .8;
}

.pjra-bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.pjra-from-black-20 {
  --tw-gradient-from: #0003 var(--tw-gradient-from-position);
  --tw-gradient-to: #0000 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.pjra-p-1 {
  padding: .25rem;
}

.pjra-p-1\.5 {
  padding: .375rem;
}

.pjra-p-2 {
  padding: .5rem;
}

.pjra-px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.pjra-px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.pjra-py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.pjra-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.pjra-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.pjra-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.pjra-font-bold {
  font-weight: 700;
}

.pjra-font-normal {
  font-weight: 400;
}

.pjra-leading-\[1\.66667\] {
  line-height: 1.66667;
}

.pjra-tracking-\[0\.02em\] {
  letter-spacing: .02em;
}

.pjra-text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
}

.pjra-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.pjra-opacity-\[0\.72\] {
  opacity: .72;
}

.pjra-drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px #00000012) drop-shadow(0 2px 2px #0000000f);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.pjra-transition-height {
  transition-property: height;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.pjra-transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.\[clip-path\:inset\(0_0_0_0\)\] {
  clip-path: inset(0);
}

.hover\:pjra-scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:pjra-scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.hover\:pjra-bg-opacity-95:hover {
  --tw-bg-opacity: .95;
}

@media (width >= 640px) {
  .sm\:pjra-left-0 {
    left: 0;
  }

  .sm\:pjra-top-0 {
    top: 0;
  }

  .sm\:pjra-h-full {
    height: 100%;
  }

  .sm\:pjra-max-w-\[400px\] {
    max-width: 400px;
  }

  .sm\:pjra-flex-col {
    flex-direction: column;
  }
}

@media (width >= 768px) {
  .md\:pjra-flex {
    display: flex;
  }

  .md\:pjra-hidden {
    display: none;
  }

  .md\:pjra-h-\[72px\] {
    height: 72px;
  }
}

@media (width >= 1024px) {
  .lg\:pjra-flex {
    display: flex;
  }

  .lg\:pjra-hidden {
    display: none;
  }
}

.\[\&_\.gdpr-cogwheel-button\]\:\!pjra-z-\[0\] .gdpr-cogwheel-button {
  z-index: 0 !important;
}
/*# sourceMappingURL=module.css.map */
